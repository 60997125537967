@font-face {
    font-family: 'noto_sansregular';
    src: url('./fonts/notosans-regular-webfont.woff2') format('woff2'), url('./fonts/notosans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'noto_sansmedium';
    src: url('./fonts/notosans-medium-webfont.woff2') format('woff2'), url('./fonts/notosans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'noto_sansbold';
    src: url('./fonts/notosans-bold-webfont.woff2') format('woff2'), url('./fonts/notosans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'noto_sansregular';
}

.header {
    box-shadow: inset 0px -1px 0px #E0E0E0 !important;
}

.wrapper {
    margin-top: 40px !important;
}

.logoImg {
    margin-right: 16px;
    border-right: solid 1px #C4C4C4;
    padding-right: 20px;
}

.leftGrp {
    border: 1px solid #E0E0E0;
    border-radius: 4px;
}

.title {
    background: #FAFAFA;
    font-weight: bold;
    padding: 20px 32px 20px 32px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.circlenum {
    background: #F8761F;
    text-align: center;
    color: #fff;
    display: inline-block;
    margin-right: 12px;
    line-height: 32px;
    font-weight: 600;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.formGrp {
    padding: 32px !important;
    box-sizing: border-box;
    width: 100%;
}

.formGrp .label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
    margin-bottom: 12px;
    display: inline-block;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    padding: 4px 8px !important;
}

.formIn {
    padding: 24px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
}

.mb-28 {
    margin-bottom: 28px;
}

.pos-relative {
    position: relative;
}

.text-right {
    text-align: right;
}

.text-right .nextBtn {
    background-color: #0C6CFC;
}

.MuiButton-sizeLarge {
    font-weight: bold;
    font-size: 14px;
}

.rightTab .circlenum {
    background: #E0E0E0;
}

.rightTab .active {
    border: solid 2px #F8761F;
}

.rightTab .active .circlenum {
    background: #F8761F;
}

.rightTab .MuiPaper-rounded {
    cursor: pointer;
    color: #000000;
}

.thanksPg {
    max-width: 762px;
    margin: 96px auto 0;
}

.errorSummary {
    margin-bottom: 20px;
    white-space: pre-wrap;
    padding: 10px;
    border: 1px solid red;
}